import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";

function AllBill() {
  //   const [show, setShow] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  //   aside
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //   search select

  // firm
  const optionsFirm = [
    { value: "one", label: "Firm Person One" },
    { value: "two", label: "Firm Person Two" },
    { value: "three", label: "Firm Person Three" },
    { value: "four", label: "Firm Person Four" },
  ];

  const [selectedOptionFirm, setSelectedOptionFirm] = useState(null);

  const handleChangeFirm = (selectedOptionFirm) => {
    setSelectedOptionFirm(selectedOptionFirm);
    console.log("Selected Option:", selectedOptionFirm);
  };
  //   bill select
  const optionsBill = [
    { value: "bill1", label: "Bill All" },
    { value: "bill2", label: "Bill Power" },
    { value: "bill3", label: "Bill Electricity" },
    { value: "bill4", label: "Bill Wifi" },
  ];

  const [selectedOptionBill, setSelectedOptionBill] = useState(null);

  const handleChangeBill = (selectedOptionBill) => {
    setSelectedOptionBill(selectedOptionBill);
    console.log("Selected Option:", selectedOptionBill);
  };
  //   operator select
  const optionsOperator = [
    { value: "Operator1", label: "Bsnl" },
    { value: "Operator2", label: "Airtel" },
    { value: "Operator3", label: "Jio" },
    { value: "Operator4", label: "Voda" },
  ];

  const [selectedOptionOperator, setSelectedOptionOperator] = useState(null);

  const handleChangeOperator = (selectedOptionOperator) => {
    setSelectedOptionOperator(selectedOptionOperator);
    console.log("Selected Option:", selectedOptionOperator);
  };
  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">All Bill</h1>
        </div>
        <div className="d-flex mb-4">
          <div className="maxw-300">
            <input
              type="search"
              className="input-box minw-300"
              placeholder="Search"
            />
          </div>
          <div className="ms-auto">
            <button
              className="button button-brown px-4"
              type="button"
              onClick={() => setDisplayModal(true)}
            >
              Download sample
            </button>
            <button className="button button-brown px-4 ms-5" type="button">
              Bulk upload
            </button>
            <button
              className="button button-brown px-4 ms-5"
              type="button"
              onClick={handleShow}
            >
              + Add Bills
            </button>
          </div>
        </div>
        <div className="maxw-300 mb-4">
          <select className="input-box" id="electricity" name="electricity">
            <option value="">Electricity</option>
            <option value="">MCG</option>
            <option value="">MCG</option>
            <option value="">MCG</option>
            <option value="">MCG</option>
          </select>
        </div>

        <div className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th>Firm/Person</th>
                <th>Code</th>
                <th>Operator</th>
                <th>Consumer code</th>
                <th>Past Bill Amount</th>
                <th>New Bill Amount</th>
                <th>Due Date</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            <tbody>
              <tr>
                <td>Himcon</td>
                <td>H001</td>
                <td>Noida Power Company Limited</td>
                <td>9876543</td>
                <td>1000</td>
                <td>1200</td>
                <td>15 oct, 2024</td>
                <td>
                  <span className="badge rounded-2 text-bg-paid">Paid</span>
                </td>
              </tr>

              <tr>
                <td>Himcon</td>
                <td>H001</td>
                <td>Noida Power Company Limited</td>
                <td>9876543</td>
                <td>1000</td>
                <td>1200</td>
                <td>15 oct, 2024</td>
                <td>
                  <span className="badge rounded-2 text-bg-unpaid">
                    Un paid
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* modal himcon */}
      <Modal
        show={displayModal}
        centered
        onHide={() => setDisplayModal(false)}
        aria-labelledby="example-custom-modal-styling-title"
        className="modal-min-w90"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="text-center w-100 text-brown"
          >
            Himcon
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-gray pt-0">
          <div className="d-flex text-center">
            <div className="mx-auto fs-22 fw-500">Consumer Code- 2255336</div>
          </div>
          <div className="fs-22 fw-500">Noida Power Company Limited</div>
          <div className="tbl-scroller">
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th>Bill Number</th>
                  <th>Bill Amount</th>
                  <th>Bill Date</th>
                  <th>Due Date</th>
                  <th>Paid Date</th>
                  <th>Transaction ID</th>
                </tr>
              </thead>
              <tbody className="tbody-gap">
                <tr>
                  <td colSpan="13"></td>
                </tr>
              </tbody>

              <tr>
                <td>BMK22334</td>
                <td className="tbl-br-l">Rs 1400</td>
                <td className="tbl-br-l">8-10-2024</td>
                <td className="tbl-br-l">15-10-2024</td>
                <td className="tbl-br-l">10-10-2024</td>
                <td className="tbl-br-l">ALT112255</td>
              </tr>
              <tr>
                <td>BMK22334</td>
                <td className="tbl-br-l">Rs 1400</td>
                <td className="tbl-br-l">8-10-2024</td>
                <td className="tbl-br-l">15-10-2024</td>
                <td className="tbl-br-l">10-10-2024</td>
                <td className="tbl-br-l">ALT112255</td>
              </tr>
              <tr>
                <td>BMK22334</td>
                <td className="tbl-br-l">Rs 1400</td>
                <td className="tbl-br-l">8-10-2024</td>
                <td className="tbl-br-l">15-10-2024</td>
                <td className="tbl-br-l">10-10-2024</td>
                <td className="tbl-br-l">ALT112255</td>
              </tr>
              <tr>
                <td>BMK22334</td>
                <td className="tbl-br-l">Rs 1400</td>
                <td className="tbl-br-l">8-10-2024</td>
                <td className="tbl-br-l">15-10-2024</td>
                <td className="tbl-br-l">10-10-2024</td>
                <td className="tbl-br-l">ALT112255</td>
              </tr>
              <tr>
                <td>BMK22334</td>
                <td className="tbl-br-l">Rs 1400</td>
                <td className="tbl-br-l">8-10-2024</td>
                <td className="tbl-br-l">15-10-2024</td>
                <td className="tbl-br-l">10-10-2024</td>
                <td className="tbl-br-l">ALT112255</td>
              </tr>
              <tr>
                <td>BMK22334</td>
                <td className="tbl-br-l">Rs 1400</td>
                <td className="tbl-br-l">8-10-2024</td>
                <td className="tbl-br-l">15-10-2024</td>
                <td className="tbl-br-l">10-10-2024</td>
                <td className="tbl-br-l">ALT112255</td>
              </tr>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      {/* aside pop */}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-brown">
            <span className="text-uderline">Add New Bill</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="text-gray">
          <div className="mb-4 select-box">
            <label htmlFor="person" className="mb-10 d-block">
              Firm/Person
            </label>
            <Select
              value={selectedOptionFirm}
              onChange={handleChangeFirm}
              options={optionsFirm}
              placeholder="Select Operator"
              isSearchable
            />
          </div>

          <div className="mb-4 select-box">
            <label htmlFor="BillType" className="mb-10 d-block">
              Bill Type
            </label>
            <Select
              value={selectedOptionBill}
              onChange={handleChangeBill}
              options={optionsBill}
              placeholder="Select Operator"
              isSearchable
            />
          </div>

          <div className="mb-4 select-box">
            <label htmlFor="Operator" className="mb-10 d-block">
              Choose Operator
            </label>
            <Select
              value={selectedOptionOperator}
              onChange={handleChangeOperator}
              options={optionsOperator}
              placeholder="Select Operator"
              isSearchable
            />
          </div>

          <div className="mb-4">
            <label htmlFor="Operator" className="mb-10 d-block">
              Consumer Number
            </label>
            <input
              type="text"
              className="input-box"
              placeholder="Enter number"
            />
          </div>

          <div className="mb-4 d-flex justify-content-end">
            <button className="button button-default px-4 me-5" type="button">
              Cancel
            </button>
            <button className="button button-brown px-4" type="button">
              Submit
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default AllBill;
