import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Accordion } from "react-bootstrap";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import "bootstrap/dist/css/bootstrap.min.css";

function CashTracking() {
  const [rows, setRows] = useState([]);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  const [selectedUser, setSelectedUser] = useState("H000");
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [userList, setUserList] = useState([]);
  const navigate = useNavigate();

  async function getRows(userCode, fromDate, toDate) {
    try {
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/cashtracking`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          user_code: userCode,
          from_date: fromDate,
          to_date: toDate,
        },
      });

      return response?.data?.data || [];
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      return [];
    }
  }

  async function getContractors() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getcontractorsbyledgermaster`,
        method: "GET",
        params: {
          ledger_master: "Head Office,Employee",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setUserList(
          response.data?.data ||
            [
              // {
              //   code: "E001",
              //   name: "E001",
              //   id: 1,
              // },
              // {
              //   code: "V001",
              //   name: "V001",
              //   id: 2,
              // },
            ]
        );
      } else {
        setUserList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  useEffect(() => {
    (async () => {
      setIsGettingRows(true);
      const initialRows = await getRows(
        selectedUser,
        selectedFromDate,
        selectedToDate
      );
      setRows(initialRows);
      setIsGettingRows(false);
    })();
  }, [selectedUser, selectedFromDate, selectedToDate]);

  useEffect(() => {
    getContractors();
  }, []);

  const NestedAccordion = ({ rows }) => {
    const [nestedRows, setNestedRows] = useState({});
    const [loadingNestedRows, setLoadingNestedRows] = useState({});
    const [openNestedAccordion, setOpenNestedAccordion] = useState(null);

    const fetchNestedRows = async (index, toCode) => {
      if (!nestedRows[index]) {
        setLoadingNestedRows((prev) => ({
          ...prev,
          [index]: true,
        }));
        const nestedData = await getRows(
          toCode,
          selectedFromDate,
          selectedToDate
        );
        setNestedRows((prev) => ({
          ...prev,
          [index]: nestedData,
        }));
        setLoadingNestedRows((prev) => ({
          ...prev,
          [index]: false,
        }));
      }
    };

    const handleNestedAccordionToggle = (index, toCode) => {
      setOpenNestedAccordion(index === openNestedAccordion ? null : index);
      if (index !== openNestedAccordion) {
        fetchNestedRows(index, toCode);
      }
    };

    return (
      <Accordion activeKey={openNestedAccordion}>
        {rows.map((row, index) => (
          <Accordion.Item key={index} eventKey={index}>
            <Accordion.Header
              className=""
              onClick={() => handleNestedAccordionToggle(index, row.to_code)}
            >
              <div className="d-flex accordion-header w-100">
                <div className="bold">
                  {moment(row?.date).format("DD MMM YYYY")}
                </div>
                <div className="divider" />

                <div className="">
                  <span className="fw-600">From: </span>
                  {row?.from_code}
                  {row?.fromdata?.name ? "-" : ""}
                  {row?.fromdata?.name}
                </div>

                <div className="divider" />

                <div className="">
                  <span className="fw-600">To: </span>
                  {row?.to_code}
                  {row?.todata?.name ? "-" : ""}
                  {row?.todata?.name}
                </div>
                <div className="divider" />

                <div className="">
                  <span className="fw-600">Paid Amount: </span>
                  {row?.debit_amount}
                </div>
                <div className="divider" />

                <div className="">
                  <span className="fw-600">Received Amount: </span>
                  {row?.credit_amount}
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {loadingNestedRows[index] ? (
                <Loader color={"#aa741f"} />
              ) : nestedRows[index]?.length ? (
                <NestedAccordion rows={nestedRows[index]} />
              ) : (
                <div>No data found</div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    );
  };

  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Cash Tracking</h1>
        </div>

        <div className="mb-20 d-flex gray justify-content-between">
          <div className="d-flex">
            <div className="w-45 me-4">
              <label htmlFor="" className="mb-10 d-block">
                Choose User
              </label>
              <select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                className="input-box"
                id="cars"
                name="cars"
              >
                {userList.map((user) => (
                  <option key={user?.id} value={user?.code}>
                    {user?.code + "-" + user?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                From Date
              </label>
              <input
                value={selectedFromDate}
                onChange={(e) => setSelectedFromDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div>
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                To Date
              </label>
              <input
                value={selectedToDate}
                onChange={(e) => setSelectedToDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div>
          </div>
        </div>

        <div className="">
          {isGettingRows ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          ) : rows?.length ? (
            <NestedAccordion rows={rows} />
          ) : (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>
      </div>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
}

export default CashTracking;
