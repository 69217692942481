import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "./Loader";

function AddMobileCredentials({
  showAddCred,
  handleCloseAddCred,
  handleAddEditMobileCreds,
  isUpdatingCreds,
}) {
  const context = useOutletContext();
  const reports = context?.reports || [];

  // const reports = JSON.parse(localStorage.getItem("him_reports")) || [];
  const [selectedReports, setSelectedReports] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleClose = () => {
    setUsername("");
    setPassword("");
    setSelectedReports([]);
    handleCloseAddCred();
  };

  useEffect(() => {
    if (showAddCred?.id) {
      setUsername(showAddCred?.him_mobile_user?.username);
      setPassword(showAddCred?.him_mobile_user?.password);
      setSelectedReports(
        showAddCred?.him_mobile_report_accesses?.map(
          (report) => report?.report_id
        ) || []
      );
    }
  }, [showAddCred]);

  return (
    <Modal show={showAddCred} onHide={handleClose} animation={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Mobile Credentials</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleAddEditMobileCreds(
              e,
              username,
              password,
              selectedReports,
              handleClose
            )
          }
        >
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Username
            </label>
            <input
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Username"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Password
            </label>
            <input
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Password"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Select reports
            </label>

            <div className="d-flex flex-wrap my-2">
              {reports
                ?.filter((report) => report?.status)
                ?.map((report, index) => {
                  return (
                    <div
                      key={report?.report_id + "reportcheckbox"}
                      className="form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        onChange={(e) => {
                          // console.log(e.target.checked);
                          if (e.target.checked) {
                            setSelectedReports([
                              ...selectedReports,
                              report?.report_id,
                            ]);
                          } else {
                            setSelectedReports(
                              selectedReports.filter(
                                (item) => item !== report?.report_id
                              )
                            );
                          }
                        }}
                        checked={selectedReports.includes(report?.report_id)}
                        value={report?.report_id}
                        id={report?.report_id + "id"}
                      />
                      <label
                        class="form-check-label"
                        for={report?.report_id + "id"}
                      >
                        {report?.report_name}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="mb-2">
            <button
              disabled={isUpdatingCreds}
              type="submit"
              className="button button-brown w-100"
            >
              {isUpdatingCreds ? <Loader /> : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddMobileCredentials;
