import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import UploadFile from "../components/UploadFile";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddMobileCredentials from "../components/AddMobileCredentials";
import Loader from "../components/Loader";
import moment from "moment";

function DailyTransactionHeadOffice() {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  // const [page, setPage] = useState(0);
  // const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  // const [totalCount, setTotalCount] = useState(0);
  const [isGettingRows, setIsGettingRows] = useState(false);
  // const [isUpdatingCreds, setIsUpdatingCreds] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedFile(null);
  };
  const handleShow = () => setShow(true);

  // const [showAddCred, setShowAddCred] = useState(false);

  // const handleCloseAddCred = () => setShowAddCred(false);
  // const handleShowAddCred = (data) => setShowAddCred(data);

  const navigate = useNavigate();

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  async function handleFileUpload(event) {
    // event && event?.preventDefault();
    if (!selectedFile) {
      setError({ title: "Error", err: "Please select a file to upload" });
      setShowErrModal(true);
      return;
    }
    setIsUploading(true);
    try {
      const token = localStorage.getItem("him_user_token");
      const formData = new FormData();
      // rawFile?.forEach((file) => {
      formData.append("document", selectedFile);
      // });

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/uploadcodesheet`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: formData,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        handleClose();
        setTimeout(() => {
          !response?.data?.errorItems?.length && handleCloseSuccess();
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsUploading(false);
      // setPage("0");
    }
  }

  async function getRows() {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/headofficetransaction`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        // params: {
        //   page: parseInt(page),
        //   limit: limit,
        // },
      });

      setRows(response?.data?.data);
      // setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  // async function handleAddEditMobileCreds(
  //   event,
  //   username,
  //   password,
  //   selectedReports,
  //   handleCloseCallback
  // ) {
  //   event && event?.preventDefault();
  //   if (!selectedReports?.length) {
  //     setError({ title: "Error", err: "Please select atleast 1 report." });
  //     setShowErrModal(true);
  //     return;
  //   }
  //   setIsUpdatingCreds(true);
  //   try {
  //     const token = localStorage.getItem("him_user_token");

  //     const data = {
  //       username,
  //       password,
  //       allowed_reports: selectedReports,
  //       contractor_id: showAddCred?.id,
  //     };

  //     const response = await axios({
  //       url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/addeditmobileuser`,
  //       method: "POST",
  //       headers: {
  //         jwt_token: token,
  //       },
  //       data: data,
  //     });

  //     if (response?.data?.success) {
  //       setshowSuccess(true);
  //       setSuccessMessagePopup(response.data?.msg);
  //       setTimeout(() => {
  //         handleCloseSuccess();
  //         handleCloseCallback ? handleCloseCallback() : handleCloseAddCred();
  //       }, 5000);
  //     } else {
  //       setError({ title: "Error", err: response?.data?.msg });
  //       setShowErrModal(true);
  //     }
  //   } catch (error) {
  //     setShowErrModal(true);
  //     setError({ title: "Error", err: error?.message });
  //     if (error?.response?.status == 403) {
  //       localStorage.removeItem("him_user_token");
  //       localStorage.removeItem("him_user_data");
  //       navigate("/login");
  //       return;
  //     }

  //     if (error?.code === "ECONNABORTED") {
  //       console.log("Request timed out");
  //     } else {
  //       console.log("An error occurred:", error);
  //     }
  //   } finally {
  //     setIsUpdatingCreds(false);
  //     setPage("0");
  //   }
  // }
  useEffect(() => {
    getRows();
    // }, [page, limit]);
  }, []);
  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Daily transaction head office</h1>
          {/* <div className="ms-auto">
            <button
              className="button button-brown px-4"
              type="button"
              onClick={handleShow}
            >
              Upload
            </button>
          </div> */}
        </div>

        {/* new add */}
        {/* <div className="mb-20 d-flex gray">
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose debitor
            </label>
            <select className="input-box" id="cars" name="cars">
              <option value="">MCG</option>
              <option value="">MCG</option>
              <option value="">MCG</option>
              <option value="">MCG</option>
              <option value="">MCG</option>
            </select>
          </div>
          <div className="w-45 mr-30">
            <label htmlFor="" className="mb-10 d-block">
              Choose title
            </label>
            <select className="input-box" id="cars" name="cars">
              <option value="title">Choose title</option>
              <option value="title">Choose title #1</option>
              <option value="title">Choose title #2</option>
              <option value="title">Choose title #3</option>
              <option value="title">Choose title #4</option>
            </select>
          </div>
        </div>
        <hr className="mr-30 my-10"></hr>
        <div className="d-flex jc-between my-10">
          <div>
            <button type="button" className="button button-brown mr-30">
              View grid
            </button>
            <button type="button" className="button button-brown mr-30">
              Save
            </button>
            <button className="btn-undo" type="button">
              <svg className="icon fs-30 pt-10">
                <use href="#icon_undo"></use>
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={handleShowAddCred}
            >
              Export
            </button>
          </div>
        </div> */}
        <div className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th> Sr. No. </th>
                <th> Date </th>
                <th> From </th>
                <th> To </th>
                <th> Cash Amount Paid </th>
                <th> Cash Amount Received </th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            {!isGettingRows && (
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    {/* <td>{parseInt(page) * limit + index + 1}</td> */}
                    <td>{index + 1}</td>
                    <td>{moment(row?.date).format("DD MMM YYYY")}</td>
                    <td>
                      {row?.from_code} {row?.fromdata?.name ? "-" : ""}{" "}
                      {row?.fromdata?.name}
                    </td>
                    <td>
                      {row?.to_code} {row?.todata?.name ? "-" : ""}{" "}
                      {row?.todata?.name}
                    </td>
                    <td>{row?.debit_amount}</td>
                    <td>{row?.credit_amount}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>

        {/* <form className="d-flex jc-between pt-30">
          <select
            value={limit}
            onChange={(e) => {
              // setLimit(e.target.value);
              // setPage(0);
            }}
            name="cars"
            id="cars"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={90}>90</option>
            <option value={100}>100</option>
          </select>
          <div className="pagination">
            <button
              disabled={parseInt(page) === 0}
              onClick={() => setPage(parseInt(page) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_leftarrow"></use>
              </svg>
            </button>
            <span>
              Page {parseInt(page) + 1} of {Math.ceil(totalCount / limit)}
            </span>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(parseInt(page) + 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_rightarrow"></use>
              </svg>
            </button>
          </div>
        </form> */}
      </div>

      <UploadFile
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        show={show}
        setShow={setShow}
        onUpload={handleFileUpload}
        isUploading={isUploading}
      />
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      {/* <AddMobileCredentials
        showAddCred={showAddCred}
        handleCloseAddCred={handleCloseAddCred}
        handleAddEditMobileCreds={handleAddEditMobileCreds}
        isUpdatingCreds={isUpdatingCreds}
      /> */}
    </>
  );
}

export default DailyTransactionHeadOffice;
