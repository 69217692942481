import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import LayoutOutlet from "./LayoutOutlet";
import AllCodeSheet from "../pages/AllCodeSheet";
import DynamicReport from "../pages/DynamicReport";
import DailyVendorRequirement from "../pages/reports/DailyVendorRequirement";
import HardCopyOfBillReceivings from "../pages/reports/HardCopyOfBillReceivings";
import ArticleAndGst from "../pages/ArticleAndGst";
import VendorPortalBillUpdation from "../pages/reports/VendorPortalBillUpdation";
import DailyCashTransactions from "../pages/reports/DailyCashTransactions";
import VehicleChallan from "../pages/reports/VehicleChallan";
import TotalStaffDetails from "../pages/reports/TotalStaffDetails";
import VehicleService from "../pages/reports/VehiceService";
import Donations from "../pages/reports/Donations";
import DailyTransactionHeadOffice from "../pages/DailyTransactionHeadOffice";
import HeadOfficeCashReceiving from "../pages/HeadOfficeCashReceiving";
import HeadOfficeCashPayment from "../pages/HeadOfficeCashPayment";
import CashTracking from "../pages/CashTracking";
import AllBill from "../pages/AllBill";

function Routing() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<LayoutOutlet />}>
            <Route index element={<AllCodeSheet />}></Route>
            <Route path="article_gst" element={<ArticleAndGst />}></Route>
            <Route
              path="daily_transaction_head_office"
              element={<DailyTransactionHeadOffice />}
            ></Route>
            <Route
              path="head_office_cash_receiving"
              element={<HeadOfficeCashReceiving />}
            ></Route>
            <Route
              path="head_office_cash_payment"
              element={<HeadOfficeCashPayment />}
            ></Route>
            <Route path="cash_tracking" element={<CashTracking />}></Route>
            <Route path="all_bill" element={<AllBill />}></Route>
            {/* <Route path="report/:report" element={<DynamicReport />}></Route> */}
            <Route
              path="report/daily_vendor_requirement"
              element={<DailyVendorRequirement />}
            ></Route>
            <Route
              path="report/hard_copy_of_bill_receivings"
              element={<HardCopyOfBillReceivings />}
            ></Route>
            <Route
              path="report/vendor_portal_bill_updation"
              element={<VendorPortalBillUpdation />}
            ></Route>
            <Route
              path="report/daily_cash_transactions"
              element={<DailyCashTransactions />}
            ></Route>
            <Route
              path="report/vehicle_challan"
              element={<VehicleChallan />}
            ></Route>
            <Route
              path="report/total_staff_details"
              element={<TotalStaffDetails />}
            ></Route>
            <Route
              path="report/vehicle_service"
              element={<VehicleService />}
            ></Route>
            <Route path="report/donations" element={<Donations />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routing;
